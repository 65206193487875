<template>
  <main class="view view--task">
    <section class="header">
      <div class="header__left"></div>
      <div class="header__center">
        <p class="title">
          {{ this.$helper.ucfirst(this.$moment(slot.date).format("dddd")) }}
        </p>
        <p class="subtitle">
          {{ this.$moment(slot.date).format("DD/MM/YYYY") }}
        </p>
      </div>
      <div class="header__right">
        <button
          v-on:click="$router.go(-1)"
          class="right btn btn-close"
        ></button>
      </div>
    </section>
    <section v-if="!online" class="alert alert-warning">
      {{
        $t(
          "You cannot create tasks because you do not have a internet connection."
        )
      }}
    </section>
    <section class="accordion">
      <article class="accordion-item accordion-item--active">
        <button class="accordion-item__header">
          <div class="img"><img src="/img/icon-pin.png" alt="" /></div>
          <div class="title">{{ $t("Projectinfo") }}</div>
        </button>
        <div class="accordion-item__body">
          <table>
            <tr>
              <td width="100">{{ $t("Task") }}<em>*</em>:</td>
              <td>
                <button v-on:click="showTasksPopup" class="btn-item-select">
                  {{ slot.task.title }}
                </button>
                <SelectPopup
                  v-on:itemselected="selectTask"
                  ref="tasksPopup"
                  :header="'Taak selecteren'"
                  :listsearch="true"
                  :items="tasksToShow"
                ></SelectPopup>
              </td>
            </tr>
            <tr v-if="slot.task.description && showTaskDescription" class="small">
              <td></td>
              <td v-html="slot.task.description"></td>
            </tr>
            <tr>
              <td width="100">{{ $t("Project") }}:</td>
              <td>
                <button v-on:click="showProjectsPopup" class="btn-item-select">
                  {{ project.title }}
                </button>
                <SelectPopup
                  v-on:itemselected="selectProject"
                  ref="projectsPopup"
                  :header="'Project selecteren'"
                  :listsearch="true"
                  :items="projectsToShow"
                ></SelectPopup>
              </td>
            </tr>
            <tr v-if="project && project.description && showProjectDescription" class="small">
              <td></td>
              <td>
                {{ project.description }}
              </td>
            </tr>
            <tr>
              <td width="100">{{ $t("Client") }}:</td>
              <td>
                <button v-on:click="showClientsPopup" class="btn-item-select">
                  {{ client.title }}
                </button>
                <SelectPopup
                  v-on:itemselected="selectClient"
                  ref="clientsPopup"
                  :header="'Klant selecteren'"
                  :listsearch="true"
                  :items="clients"
                ></SelectPopup>
              </td>
            </tr>
          </table>
        </div>
      </article>
      <article class="accordion-item">
        <button class="accordion-item__header">
          <div class="img"><img src="/img/icon-clock.png" alt="" /></div>
          <div class="title">{{ $t("Timeregistrations") }}</div>
        </button>
        <div v-for="(tracking, index) in slot.timetracking" :key="index">
          <TimeRegistration
            :timeslot="slot"
            :trackingAccuracy="settings.accuracy_of_timetracking"
            v-on:update="trackingUpdated()"
            v-on:delete="removeTracking(index)"
            v-model="slot.timetracking[index]"
            :equipment="equipment"
            :collapsed="slot.timetracking.length > 1"
            :settings="settings"
          ></TimeRegistration>
        </div>
        <div class="timeregistration">
          <button class="btn-add btn-add--lg" v-on:click="addTracking"></button>
        </div>
      </article>
    </section>

    <div
      class="alert alert-warning btn-save"
      v-if="!(slot.task.id && slot.timetracking.length > 0)"
    >
      <span>{{ $t("Select a task and add a timetracking") }}</span>
    </div>
    <div
      class="alert alert-warning btn-save"
      v-if="slot.task.id && slot.timetracking.length > 0 && !valid()"
    >
      {{ $t("Some values are invalid or incomplete") }}
    </div>

    <button
      class="btn-save"
      v-on:click="saveTracking"
      v-if="slot.task.id && slot.timetracking.length > 0 && valid()"
    >
      <img :src="saveButton.icon" /> <span>{{ saveButton.text }}</span>
    </button>
  </main>
</template>

<script>
import TimeRegistration from "./../components/TimeRegistration.vue";
import SelectPopup from "./../components/SelectPopup.vue";

export default {
  name: "taskNew",
  components: {
    TimeRegistration,
    SelectPopup,
  },
  methods: {
    trackingUpdated: function () {
      this.$forceUpdate();
    },
    valid: function () {
      if (this.slot.timetracking) {
        for (let i = 0; i < this.slot.timetracking.length; i++) {
          const element = this.slot.timetracking[i];
          if (!element.valid) {
            return false;
          }
        }
      }

      return true;
    },
    showClientsPopup: function () {
      this.$refs.clientsPopup.show();
    },
    selectClient: function (item) {
      this.$refs.clientsPopup.hide();
      this.client = item;
      this.slot.task = { title: this.$t("Select...") };
      this.project = { title: this.$t("None") };
      this.updateDropdowns();
    },
    showProjectsPopup: function () {
      this.$refs.projectsPopup.show();
    },
    selectProject: function (item) {
      this.$refs.projectsPopup.hide();
      this.project = item;
      this.slot.task = { title: this.$t("Select...") };
      if (this.project.clientable) {
        this.client = this.project.clientable;
      } else {
        this.client = { title: this.$t("None") };
      }
      this.updateDropdowns();
    },
    showTasksPopup: function () {
      this.$refs.tasksPopup.show();
    },
    selectTask: function (item) {
      this.$refs.tasksPopup.hide();
      this.slot.task = item;
      if (this.slot.task.relatable) {
        if (this.slot.task.relatable_type == "project") {
          this.project = this.slot.task.relatable;
          if (this.project.clientable) {
            this.client = this.project.clientable;
          }
        } else {
          this.project = { title: this.$t("None") };
          this.client = this.slot.task.relatable;
        }
      } else {
        this.project = { title: this.$t("None") };
        this.client = { title: this.$t("None") };
      }
      this.updateDropdowns();
    },
    removeTracking: function (index) {
      this.slot.timetracking.splice(index, 1);
    },
    addTracking: function () {
      this.slot.timetracking.push({
        start: this.slot.time_start,
        end: this.showEndtime ? this.slot.time_end : "00:00",
        pause: "00:00",
        remarks: "",
        slot_id: null,
        task_id: this.slot.task.id,
        task_is_finished: false,
        date: this.slot.date,
      });
    },
    saveTracking: function () {
      if (this.loading) {
        return false;
      }

      this.loading = true;

      let sendData = {
        slot: this.slot,
        timetrackings: JSON.parse(JSON.stringify(this.slot.timetracking)),
      };

      for (let index = 0; index < sendData.timetrackings.length; index++) {
        sendData.timetrackings[index].equipment_time = this.$moment
          .duration(sendData.timetrackings[index].equipment_time)
          .asMinutes();
      }

      var self = this;
      this.$api
        .postTracking(this.$config.get("account").id, sendData)
        .then(function () {
          self.$router.push({
            name: "tasksaved",
            query: { date: self.slot.date },
          });
        })
        .catch(function () {
          return self.$database
            .writeData("tracking-sync", sendData)
            .then(function () {
              self.$router.push({
                name: "tasksaved",
                query: { date: self.slot.date, offline: true },
              });
            });
        });
    },
    updateDropdowns: function () {
      var client = this.client.id;
      var client_type = this.client.clientable_type;
      var project = this.project.id;

      this.projectsToShow = this.projects;
      this.tasksToShow = this.tasks;

      if (client) {
        this.projectsToShow = [];
        this.tasksToShow = [];

        var valid_projects = [];
        for (let i = 0; i < this.projects.length; i++) {
          if (!this.projects[i].id) {
            this.projectsToShow.push(this.projects[i]);
          } else {
            if (this.projects[i].clientable) {
              if (
                this.projects[i].clientable_type == client_type &&
                this.projects[i].clientable.id == client
              ) {
                this.projectsToShow.push(this.projects[i]);
                valid_projects.push(this.projects[i].id);
              }
            }
          }
        }
        for (let i = 0; i < this.tasks.length; i++) {
          if (!this.tasks[i].id) {
            this.tasksToShow.push(this.tasks[i]);
          } else {
            if (this.tasks[i].relatable) {
              if (
                this.tasks[i].relatable_type == "project" &&
                valid_projects.indexOf(this.tasks[i].relatable.id) != -1
              ) {
                this.tasksToShow.push(this.tasks[i]);
              } else if (
                this.tasks[i].relatable_type == client_type &&
                this.tasks[i].relatable.id == client
              ) {
                this.tasksToShow.push(this.tasks[i]);
              }
            }
          }
        }
      }

      if (project) {
        this.tasksToShow = [];

        for (let i = 0; i < this.tasks.length; i++) {
          if (!this.tasks[i].id) {
            this.tasksToShow.push(this.tasks[i]);
          } else {
            if (this.tasks[i].relatable) {
              if (
                this.tasks[i].relatable_type == "project" &&
                this.tasks[i].relatable.id == project
              ) {
                this.tasksToShow.push(this.tasks[i]);
              }
            }
          }
        }
      }
    },
    init: function () {
      var self = this;

      this.$config.checkOnline(function (online) {
        self.online = online;
      });

      this.$database.readAllData("equipment").then((data) => {
        self.equipment = data;
      });
      this.$api.getTasks(this.$config.get("account").id).then((tasks) => {
        var projects_added = [];
        var contacts_added = [];
        var companies_added = [];

        for (let i = 0; i < tasks.length; i++) {
          const task = tasks[i];

          if (task.relatable) {
            if (task.relatable_type == "project") {
              if (task.relatable.clientable) {
                if (task.relatable.clientable_type == "contact") {
                  task.relatable.clientable.title =
                    task.relatable.clientable.last_name +
                    " " +
                    task.relatable.clientable.first_name;
                  task.relatable.clientable.clientable_type =
                    task.relatable.clientable_type;

                  if (
                    contacts_added.indexOf(task.relatable.clientable.id) == -1
                  ) {
                    contacts_added.push(task.relatable.clientable.id);
                    self.clients.push(task.relatable.clientable);
                  }
                } else if (task.relatable.clientable_type == "company") {
                  task.relatable.clientable.title =
                    task.relatable.clientable.company_name;
                  task.relatable.clientable.clientable_type =
                    task.relatable.clientable_type;

                  if (
                    companies_added.indexOf(task.relatable.clientable.id) == -1
                  ) {
                    companies_added.push(task.relatable.clientable.id);

                    self.clients.push(task.relatable.clientable);
                  }
                }
              }
              if (projects_added.indexOf(task.relatable.id) == -1) {
                projects_added.push(task.relatable.id);
                self.projects.push(task.relatable);
              }
            } else if (task.relatable_type == "contact") {
              task.relatable.title =
                task.relatable.last_name + " " + task.relatable.first_name;
              task.relatable.clientable_type = task.relatable_type;

              if (contacts_added.indexOf(task.relatable.id) == -1) {
                contacts_added.push(task.relatable.id);
                self.clients.push(task.relatable);
              }
            } else if (task.relatable_type == "company") {
              task.relatable.title = task.relatable.company_name;
              task.relatable.clientable_type = task.relatable_type;

              if (companies_added.indexOf(task.relatable.id) == -1) {
                companies_added.push(task.relatable.id);
                self.clients.push(task.relatable);
              }
            }
          }

          self.tasks.push(task);
        }

        this.updateDropdowns();
      });
    },
  },
  mounted: function () {
    var self = this;

    if (!this.$route.query.date) {
      this.$router.push({ name: "home" });
      return;
    }

    this.slot.date = this.$route.query.date;

    this.$pwa.getSettings().then((data) => {
      if (data.can_track_time_for_unplanned_task == 0) {
        this.$router.push({ name: "home" });
        return;
      }
      self.settings = data;
      this.init();
    });
  },
  data: function () {
    return {
      slot: {
        time_start: "00:00",
        time_end: "00:00",
        date: "",
        task: { title: this.$t("Select...") },
        timetracking: [],
      },
      project: { title: this.$t("None") },
      client: { title: this.$t("None") },
      equipment: [],
      settings: { accuracy_of_timetracking: 1 },
      online: true,
      loading: false,
      tasks: [{ title: this.$t("None") }],
      clients: [{ title: this.$t("None") }],
      projects: [{ title: this.$t("None") }],
      projectsToShow: [],
      tasksToShow: [],
    };
  },
  computed: {
    showEndtime: function () {
      if (this.settings.show_end_time_of_task) {
        return this.settings.show_end_time_of_task == 1;
      } else {
        return false;
      }
    },
    showProjectDescription: function () {
      if (this.settings.can_see_project_description) {
        return this.settings.can_see_project_description == 1;
      } else {
        return true;
      }
    },
    showTaskDescription: function () {
      if (this.settings.can_see_task_description) {
        return this.settings.can_see_task_description == 1;
      } else {
        return true;
      }
    },
    saveButton: function () {
      if (this.loading) {
        return { icon: "/img/loading.svg", text: this.$t("Saving...") };
      } else {
        return { icon: "/img/icon-check-white.png", text: this.$t("Save") };
      }
    },
  },
};
</script>
